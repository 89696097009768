/* The following block can be included in a custom.scss */


/* make the customizations */

// $theme-colors: (
//   // 'info': tomato,
//   //  'danger': teal
//   // 'dark': rgb(60,60,60)
//   'dark': rgb(35,35,35)
// );
$dark: rgb(30, 30, 30);
// $danger : yellow;
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* import bootstrap to set changes */

@import "~bootstrap/scss/bootstrap";
// custom style
body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", "kanit", "Open Sans", sans-serif;
    // font-family: "kanit", sans-serif;
}

body {
    overflow-x: hidden;
}

.nav-link {
    color: rgba(255, 255, 255, 0.9);
}

.nav-link:hover,
.nav-link:active {
    // color:rgba(255,255,255,0.9);
    color: rgba(255, 243, 0, 0.9);
}

.dropdown-item {
    font-size: 0.8em;
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
    background-color: $danger;
    color: rgb(255, 255, 255);
}

.table tbody tr:hover td,
.table tbody tr:hover th {
    background-color: $info;
}

.myScoreHolder {
    max-width: 900px !important;
    min-width: 420px !important;
    margin: 1.5rem auto 0.5rem auto !important;
}

.myFormHolder {
    max-width: 500px !important;
    margin: 1.5rem auto 0.5rem auto !important;
}

// Table Result
.TblResult {
    // font-size: 2rem;
}

.tblHeader th {
    font-size: 1.1em;
    font-weight: 500;
    color: white;
    padding: 15px 0;
    background-color: $danger;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)) !important
}

.idpaNumber a {
    color: $primary;
    font-weight: 500;
    text-decoration: none;
}